.tooltip {
  position: relative;
  display: inline-block;
}

.tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  position: absolute;
  z-index: 10;
  white-space: nowrap;
  transition: opacity 0.3s;
  opacity: 0;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Posizionamenti */
.tooltiptext-top {
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltiptext-bottom {
  top: 125%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltiptext-left {
  right: 125%;
  top: 50%;
  transform: translateY(-50%);
}

.tooltiptext-right {
  left: 125%;
  top: 50%;
  transform: translateY(-50%);
}
