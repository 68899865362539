/* .container {
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  max-height: 100vh;

  align-items: center;
  justify-content: center;
  text-align: center;
} */
 
/* @media only screen and (min-width: 600px) {
  .localStreamContainer {
    max-width: 150px;
  }
} */

.selfVideo {
  position: fixed;
  height: 150px;
  min-width: 100px;
  max-width: 150px;
  right: 10px;
  top: 10px;
  border-radius: 8px;
  /* background-color: #000; */
}

.mainVideo {
  max-width: 100vw;
  height: 100dvh;
  margin: 0 auto;
  z-index: 2;
  background-color: #000;
}

.actionBarWrapper {
  position: fixed;
  display: flex;
  background: rgba(154, 160, 166, 0.5);
  width: 100%;
  bottom: 0;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  z-index: 10;
}

.actionBarContainer {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  flex: 1;
  display: flex;
  align-content: center;
  justify-content: space-around;
}

.positionCenter {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #000;
}

.timerBox {
  position: fixed;
  top: 20px;
  left: 20px;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px 15px;
  border-radius: 8px;
  z-index: 10;
}

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #fff;
  font-size: 16px;
}

/* Stili per la chat */
.chatInputWrapper {
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  padding: 8px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
  width: 90%;
  max-width: 600px;
  border-radius: 20px;
}

.chatInput {
  flex: 1;
  padding: 6px 12px;
  border-radius: 15px;
  border: none;
  background: rgba(255, 255, 255, 0.9);
  margin-right: 8px;
  font-size: 16px;
  color: #333;
  height: 32px;
}

.chatInput:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
}

.sendButton {
  padding: 6px;
  border-radius: 15px;
  border: none;
  background: #0078FF;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
}

.sendButton:hover {
  background: #0056b3;
}

.sendButton:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

.messagesOverlay {
  position: fixed;
  left: 20px;
  bottom: 110px;
  max-width: 70%;
  z-index: 8;
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
  max-height: 40vh;
  overflow-y: hidden;
}

.messageItem {
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 15px;
  border-radius: 10px;
  max-width: 100%;
  word-break: break-word;
  animation: fadeIn 0.3s ease-in-out, fadeOut 0.5s ease-in-out 6.5s forwards;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.errorOverlay {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background-color: rgba(220, 38, 38, 0.9);
  padding: 12px 24px;
  border-radius: 8px;
  animation: fadeIn 0.3s ease-in-out;
}

.errorMessage {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.dismissButton {
  color: #fff
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -20px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}